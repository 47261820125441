import { createContext, useContext, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useRouter } from 'next/router';

import { type FirebaseError } from 'firebase/app';
import {
  applyActionCode,
  type AuthError,
  browserLocalPersistence,
  browserSessionPersistence,
  checkActionCode,
  confirmPasswordReset,
  onAuthStateChanged,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  type User,
} from 'firebase/auth';

import { usePreviousProtectedRoute } from '@client/components/hooks/usePreviousProtectedRoute';
import { navigationPaths } from '@client/constants/navigation';
import { apiClient } from '@client/helpers/dataFetching/apiClient';
import { auth } from '@config/firebase';
import getClientEnvironment from '@config/getClientEnvironment';
import { type AuthContext, type SignInData } from '@customTypes/auth';
import { type OfferAndRegistrationRequestBody } from '@customTypes/offerTypes';
import { type UserSignUpForm } from '@customTypes/requestBodies';
import * as Sentry from '@sentry/nextjs';

const Context = createContext({} as AuthContext);

export const useAuth = () => useContext(Context);

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { shutdown } = useIntercom();
  const { login } = navigationPaths.public;
  const router = useRouter();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<AuthError | any | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isUserIdentified, setIsUserIdentified] = useState<boolean>(false);
  const environment = getClientEnvironment();
  const previousProtectedRoute = usePreviousProtectedRoute();

  useEffect(() => {
    if (router.isReady) {
      if (error) {
        setError(undefined);
      }
    }
  }, [router.isReady]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        setUser(user);
        setIsAuthenticated(true);
        if (!isUserIdentified) {
          setIsUserIdentified(true);
          Sentry.setUser({ email: user.email ?? undefined, id: user.uid });
        }
      } else {
        setUser(null);
        setIsAuthenticated(false);
        setIsUserIdentified(false);
        Sentry.setUser(null);
      }
      setLoading(false);

      return () => unsubscribe();
    });
  }, []);

  const verifyEmail = async (code: string) => {
    try {
      await applyActionCode(auth, code);

      await auth.currentUser?.reload();
    } catch (error: any) {
      throw error;
    }
  };

  /**
   * This only works for authenticated users, if user is null function will not fire.
   * @returns
   */
  const sendAuthenticatedEmailVerificationRequest = async () => {
    if (!auth.currentUser) return { success: false, status: 400 };
    const res = await apiClient.post<{ success: boolean }>(
      `/api/users/${auth.currentUser.uid}/sendEmailVerification`,
    );
    return { success: res?.data.success ?? false, status: res?.status ?? 400 };
  };

  const signIn = async ({ email, password }: SignInData) => {
    setError(undefined);
    try {
      if (email === 'demo-account@astro.tax') {
        await setPersistence(auth, browserSessionPersistence);
      } else {
        await setPersistence(auth, browserLocalPersistence);
      }

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const token = await user.getIdToken(true);
      // This API call is complementary to having client-sides Firebase auth checks.
      // It sets authentication cookies (JWT Firebase ID token) that can
      // be used to validate server-side requests
      await fetch('/api/login', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      router.reload();
    } catch (error: any) {
      console.error(error);
      setError(error);
      throw error;
    } finally {
      shutdown();
    }
  };

  const signUp = async (
    {
      email,
      password,
      type,
      phone = '',
      firstName,
      lastName,
      astroServicesStartDate,
      incorporationStatus,
      incorporationPartner,
      vatRegimeType,
    }: UserSignUpForm,
    immediatellySignIn = true,
  ) => {
    setError(undefined);

    const result = await apiClient.post('/api/formHandler/signUpHandler', {
      data: {
        email,
        password,
        type,
        phone,
        firstName,
        lastName,
        astroServicesStartDate,
        incorporationStatus,
        incorporationPartner,
        vatRegimeType,
      },
      withCredentials: true, // To send cookies with request
    });

    if (!result) {
      setError('Could not sign up');
    }

    try {
      if (immediatellySignIn) {
        await signIn({ email, password }).finally(() => {
          shutdown();
        });
      }
    } catch (error: any) {
      setError(error);
      throw error;
    }
  };

  /**
   * @deprecated - The offer and registration are now created via webflow through api/webflowOfferAndRegistrationHandler
   * @param contract
   * @param register
   */
  const createNewOfferAndNewAccount = async ({
    contract,
    register,
  }: OfferAndRegistrationRequestBody) => {
    const result = await apiClient.post('/api/formHandler/offerAndRegistrationHandler', {
      data: { contract, register },
    });
    if (!result) {
      throw new Error('Error while creating new offer and new account');
    }
  };

  const logOut = async () => {
    setError(undefined);
    // This API call is complementary to having client-sides Firebase auth checks.
    // It removes the authentication cookies (JWT Firebase ID token)
    await fetch('/api/logout');
    signOut(auth)
      .then(async () => {
        shutdown();
        await router.push(login);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const forgotPassword = async (email: string) => {
    return await sendPasswordResetEmail(auth, email, {
      url:
        environment === 'production'
          ? 'https://dashboard.astro.tax/login'
          : 'https://dashboard-develop.astro.tax/login',
    });
  };
  const resetPassword = async (code: string, newPassword: string) => {
    await confirmPasswordReset(auth, code, newPassword).catch((error: FirebaseError) => {
      throw error;
    });
  };

  /**
   *  This function is used to verify the validity of oobCode
   * @param code - oobCode from url query params
   * @returns - void
   */
  const isOobCodeValid = async (code: string) => {
    await checkActionCode(auth, code)
      .then(value => {
        return value;
      })
      .catch(error => {
        throw error;
      });
  };

  return (
    <Context.Provider
      value={{
        isOobCodeValid,
        isAuthenticated,
        isEmailVerified: !!user?.emailVerified,
        user,
        loading,
        signIn,
        signUp,
        error,
        logOut,
        setLoading,
        forgotPassword,
        resetPassword,
        previousProtectedRoute,
        verifyEmail,
        sendAuthenticatedEmailVerificationRequest,
        createNewOfferAndNewAccount,
        isUserIdentified,
      }}>
      {children}
    </Context.Provider>
  );
};
